import React from "react";
import Helmet from 'react-helmet';
import { Container, Row, Col, Form, Button, DropdownButton, Dropdown } from 'react-bootstrap';
import { Link } from "gatsby";

/**
 * Layouts
 */
import Layout from '@layouts/default';

/**
 * Components
 */
import Intro from '@components/intro/intro';
import About from '@components/about/about';
import BookVideo from '@components/book-video/book-video';
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs';
import EntryHeading from '@components/entry-heading/entry-heading';
import LoadMorePagination from '@components/load-more-pagination/load-more-pagination';
import { graphql } from 'gatsby'
import get from 'lodash/get'
import SEO from "../components/seo"
import Newsletter from '@components/book-video/newsletter';

class NewsSubCategoryTemplate extends React.Component {

  render() {

    const Page = get(this, 'props.data.strapiNewsEvents')
    const News = get(this, 'props.data.allStrapiNewsEvents.edges')
    const Category = get(this, 'props.data.allStrapiNewsCategories.edges')
    const Sub_Category = get(this, 'props.data.allStrapiNewsSubCategories.edges')
    const Active = get(this, 'props.data.strapiNewsSubCategories')
    const ActiveMainCategory = get(this, 'props.data.strapiNewsCategories')

    const url = typeof window !== 'undefined' ? window.location.href : ''

      if(url.indexOf("news") > -1) {
       var category = "News";
      }
      else if(url.indexOf("events") > -1) {
       var category = "Events";
      }
      else if(url.indexOf("video") > -1) {
       var category = "Video";
      }


    return (
      <Layout>
        <Helmet 
          bodyAttributes={{
            class: 'static news-landing templates-news-sub-category-template-js'
          }}
        />
        <SEO title="" description="" />
        <div className="page-body">


      <Container>
        <EntryHeading Title={Active.Category_Name} Sub_Title="News, Events & Video" Content_Alignment="Left"/>
      </Container>
      <Container>


      <div className="news-filters">
        <div className="news-filter-desktop">
          <Link to="../../latest-news" className="btn btn-outline-primary cta">All Posts</Link>
          {Category.map(({node}, key) => {
            return <>

              {ActiveMainCategory.Category_Name == node.Category_Name &&
              <>
              {node.news_events.length != 0 &&
              <Link to={`../../${node.URL}`} className="btn btn-outline-primary cta active">{node.Category_Name}</Link>
              }
              </>
              }
              {ActiveMainCategory.Category_Name != node.Category_Name &&
              <>
              {node.news_events.length != 0 &&
              <Link to={`../../${node.URL}`} className="btn btn-outline-primary cta">{node.Category_Name}</Link>
              }
              </>
              }

            </>
          })}
        </div>


        <div className="news-filters news-filter-mobile">
          <DropdownButton id="dropdown-basic-button" title={ActiveMainCategory.Category_Name}>
            <Dropdown.Item selected><Link to="../../latest-news">All Posts</Link></Dropdown.Item>
            {Category.map(({node}, key) => {
              return <>
              {node.news_events.length != 0 &&
              <>
              {ActiveMainCategory.Category_Name != node.Category_Name &&
                <Dropdown.Item selected><Link to={`../../${node.URL}`}>{node.Category_Name}</Link></Dropdown.Item>
              }
              </>
              }
              </>
            })}
          </DropdownButton>
        </div>

      <div className="news-sub-category">
          <span className="main">All {Active.Category_Name}</span>  
      </div>
      </div>



      {News.length == 0 &&
        <p>Sorry, there is no posts yet</p>
      }


      <LoadMorePagination content={News} path="/about-us/property-news" LoadMore="Load More" Level="2"/>


      </Container>

        <Newsletter /> 

        
      <Breadcrumbs Parent_Label="About" Parent_URL="about/holroyds" Parent_1_Label={ActiveMainCategory.Category_Name} Parent_1_URL={`../${ActiveMainCategory.URL}`} Page_1={Active.Category_Name}/>
      </div>
      </Layout>
    )
  }
}

export default NewsSubCategoryTemplate;


export const pageQuery = graphql`
  query NewsSubCategoryTemplateSlug($slug: String!, $category_id: String!) {


  allStrapiNewsEvents(filter: {news_category: {id: {eq: $category_id}}, Sub_Category: {elemMatch: {URL: {eq: $slug}}}}, sort: {fields: Date, order: DESC}) {
    edges {
      node {
        Title
        URL
        Tile_Image {
          childImageSharp {
            fluid(quality: 90,toFormat: JPG, maxWidth: 400, maxHeight: 400, cropFocus: CENTER, fit: INSIDE, background: "#fff") {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        Date(formatString: "Do MMMM, YYYY")
        news_category {
          URL
        }
        Sub_Category {
          Category_Name
        }
        Upload_Video {
          publicURL
        }
        Embed_Video_URL
      }
    }
  }

  allStrapiNewsCategories(filter: {Publish: {eq: true}}, sort: {fields: Sort, order: ASC}) {
    edges {
      node {
        Category_Name
        URL
        news_events {
          Title
        }
      }
    }
  }


  strapiNewsCategories(strapiId: {eq: $category_id}) {
    Category_Name
    URL
    Meta_Description
    Meta_Title
    Title_H1
    Sub_Heading
  }


  allStrapiNewsSubCategories(filter: {Publish: {eq: true}}) {
    edges {
      node {
        Category_Name
        URL
        news_events {
          Title
        }
      }
    }
  }

  strapiNewsSubCategories(URL: {eq: $slug}) {
    URL
    Meta_Description
    Meta_Title
    Category_Name
  }


  }
`